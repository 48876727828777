import "./categorization.scss";
import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

const Categorization = ({ categories, currentCategory }) => {
   return (
      <div className="categorization">
         <div className="categorization__row">
            {categories.map((item, index) => {
               return (
                  <Link
                     to={
                        item.slug !== "wszystkie"
                           ? `/aktualnosci/${item.slug}`
                           : "/aktualnosci/"
                     }
                     className={classNames(
                        "button button--small categorization__button",
                        {
                           ["button--active categorization__button--active"]:
                              currentCategory === item.slug,
                        }
                     )}
                     key={index}
                     style={{
                        backgroundColor: item.newsCategory.categoryColor,
                     }}
                  >
                     {item.name}
                     <span className="categorization__amount">
                        {item.count ? item.count : "0"}
                     </span>
                  </Link>
               );
            })}
         </div>
      </div>
   );
};

export default Categorization;

// export default () => (
//    <StaticQuery
//       query={graphql`
//          {
//             data: allWpCategory {
//                nodes {
//                   name
//                   slug
//                   id
//                   count
//                }
//             }
//          }
//       `}
//       render={(data) => <Categorization {...data} />}
//    />
// );
