import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import Categorization from "../components/Categorization";
import Pagination from "../components/Pagination";
import NewsTile from "../components/NewsTile";

const NewsPage = ({ pageContext, data }) => {
   const seo = pageContext.seo;
   const listItems = data.allWpPost.nodes;

   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="news-page">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <Categorization
                  categories={pageContext.categories}
                  currentCategory={pageContext.currentCategory}
               />
               <div className="news-page__list">
                  <div className="row">
                     {listItems.length === 0 || undefined ? (
                        <div
                           style={{
                              fontSize: "1.3rem",
                              marginBottom: "30px",
                              textAlign: "center",
                           }}
                        >
                           Obecnie nie ma żadnych aktualności.
                        </div>
                     ) : (
                        listItems.map((item, index) => (
                           <div className="col-xl-4 col-md-6" key={index}>
                              <NewsTile
                                 title={item.title}
                                 thumbnail={item.featuredImage?.node}
                                 desc={item.singleNews.desc}
                                 link={item.uri}
                                 date={item.date}
                                 category={item.categories.nodes[0].name}
                                 categoryColor={
                                    item.categories.nodes[0].newsCategory
                                       .categoryColor
                                 }
                                 isNews
                              />
                           </div>
                        ))
                     )}
                  </div>
               </div>
               {listItems.length > 5 ? <Pagination {...pageContext} /> : ""}
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   query ($limit: Int!, $skip: Int!, $currentCategory: String!) {
      allWpPost(
         limit: $limit
         skip: $skip
         sort: { fields: date, order: DESC }
         filter: {
            categories: {
               nodes: { elemMatch: { slug: { eq: $currentCategory } } }
            }
         }
      ) {
         nodes {
            title
            uri
            id
            slug
            date(formatString: "DD.MM.y")
            featuredImage {
               node {
                  sourceUrl
               }
            }
            singleNews {
               desc
            }
            categories {
               nodes {
                  name
                  newsCategory {
                     categoryColor
                  }
                  slug
               }
            }
         }
      }
   }
`;

export default NewsPage;
